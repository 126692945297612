<template>
<div class="menu-list">
 <h2>买家中心</h2>
  <a-menu  :selectedKeys="selectedKeys" mode="inline" theme="light">
    <a-menu-item v-for="(item) in list" :key="item.route">
        <router-link :to="item.route + ($route.query.entityId && `?entityId=${$route.query.entityId}`)">
              {{item.menuName}}
        </router-link>
    </a-menu-item>
  </a-menu>
</div>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    data() {
        return {
            selectedKeys: [],
        };
    },
    computed: {
        ...mapGetters(['getRoleP']),
        list() {
            const menu = this.getRoleP.find(item => item.entityId == this.$route.query.entityId)
            if (menu) {
                const obj = {
                    "PM001": '/buyercenter',
                    "PM002": '/buyercenter/order-management',
                    "PM003": '/buyercenter/advance-management',
                    // "PM004": '/buyercenter/payment-management',
                    // "PM005": '/buyercenter/balance-management',
                    // "PM006": '/buyercenter/batch-management',
                    "PM007": '/buyercenter/downstream',
                    "PM008": '/buyercenter/stock',
                }
                return menu.menuListVoList.map(item => {
                    // if (item.menuCode === 'PM004') {
                    //     return ''
                    // }
                    return { ...item, route: obj[item.menuCode]}
                })
            }
            return []
        }
    },
    mounted () {
        this.updateMenu()
    },
    watch: {
    '$route' () {
        this.updateMenu()
        }
    },
    methods: {
        handleClick() {
        this.updateMenu()
        },
        updateMenu () {
            const routes = this.$route.matched.concat()
            this.selectedKeys = [ routes.pop().path ]
        }
    }
}
</script>

<style lang="less" scoped>
.menu-list {
    box-sizing: border-box;
    width: 250px;
    background-color: #fff;
    border-right: 1px solid #e8e8e8;
}
/deep/ .ant-menu-item {
    &:nth-child(1) {
        margin-top: 0;
    }
}
/deep/ .ant-menu-item {
    a {
        color: rgba(0, 0, 0, 0.65);
    }
}
/deep/ .ant-menu-item-selected {
    a {
        color: @primary;
    }
}
/deep/ .ant-menu-inline{
    border-right: none;
}
/deep/.ant-menu-item::after{
    position: absolute;
    top: 0;
    left: 0;
    border-left: 3px solid @primary;
    border-right: none;
}
h2{
    padding: 20px;
    font-size: 18px;
    font-weight: 600;
    color: rgb(102, 102, 102);
}
</style>